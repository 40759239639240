import {
  ActivityStoreClassReport,
  ModuleStoreClassReport,
} from '../../interfaces/class-report.interface';
import {
  AddPreviewFilter,
  Module,
  PreviewContentUpdate,
  UpdatePreviewFilter,
} from '../../interfaces/module.interface';
import { Action } from '@ngrx/store';
import {
  StudentReportStore,
  StudentClassReportStore,
  StudentAssessmentLastVisitedQuestionStore,
  StudentAssessmentQuestionsChoiceStore,
  StudentAssessmentQuestionsReportStore,
} from 'up';

export enum ModulesActions {
  LoadModules = '[Modules] Load Modules',
  UpdateActivityStatus = '[Modules] Update Activity Selection Status',
  UpdatePreviewContent = '[Modules] Update Activity Preview Content',
  AddPreviewFilters = '[Modules] Add Preview Filter',
  UpdatePreviewFilters = '[Modules] Update Preview Filter',
  AddModuleClassReport = '[Modules] Add Module Class Report',
  AddActivityClassReport = '[Modules] Add Activity Class Report',
  AddStudentsProgress = '[Modules] Add Students Progress',
  AddActivityStudentsProgress = '[Modules] Add Activity Students Progress',
  ClearStudentsProgress = '[Modules] Clear Student Progress',
  ClearActivityStudentsProgress = '[Modules] Clear Activity Student Progress',
  AddStudentClassReport = '[Modules] Add Student Class Report',
  UpdateLastVisitedStatus = '[Modules] Update Last Visited Status',
  AddStudentAssessmentQuestionsReport = '[Modules] Add Student Assessment Questions Report',
  UpdateStudentAssessmentQuestionsChoiceOption = '[Modules] Update Student Assessment Questions Choice Option',
  UpdateQuestionLastVisitedStatus = '[Modules] Update Question Last Visited Status',
  ClearQuestionLastVisitedStatus = '[Modules] Clear Question Last Visited Status',
}

export class LoadModules implements Action {
  public readonly type = ModulesActions.LoadModules;
  constructor(public payload: Module[]) {}
}

export class UpdatePreviewContent implements Action {
  public readonly type = ModulesActions.UpdatePreviewContent;
  constructor(public payload: PreviewContentUpdate) {}
}
export class AddPreviewFilters implements Action {
  public readonly type = ModulesActions.AddPreviewFilters;
  constructor(public payload: AddPreviewFilter) {}
}
export class UpdatePreviewFilters implements Action {
  public readonly type = ModulesActions.UpdatePreviewFilters;
  constructor(public payload: UpdatePreviewFilter) {}
}

export class AddModuleClassReport implements Action {
  public readonly type = ModulesActions.AddModuleClassReport;
  constructor(public payload: ModuleStoreClassReport) {}
}

export class AddActivityClassReport implements Action {
  public readonly type = ModulesActions.AddActivityClassReport;
  constructor(public payload: ActivityStoreClassReport) {}
}

export class AddStudentsProgress implements Action {
  public readonly type = ModulesActions.AddStudentsProgress;
  constructor(public payload: StudentReportStore) {}
}

export class ClearStudentsProgress implements Action {
  public readonly type = ModulesActions.ClearStudentsProgress;
  constructor(public payload: number) {}
}

export class ClearActivityStudentsProgress implements Action {
  public readonly type = ModulesActions.ClearActivityStudentsProgress;
  constructor(
    public payload: {
      moduleIndex: number;
      activityIndex: number;
      activityType: string;
    }
  ) {}
}

export class UpdateLastVisitedStatus implements Action {
  public readonly type = ModulesActions.UpdateLastVisitedStatus;
  constructor(public payload: number) {}
}

export class AddStudentClassReport implements Action {
  public readonly type = ModulesActions.AddStudentClassReport;
  constructor(public payload: StudentClassReportStore) {}
}

export class AddActivityStudentsProgress implements Action {
  public readonly type = ModulesActions.AddActivityStudentsProgress;
  constructor(public payload: StudentReportStore) {}
}
export class AddStudentAssessmentQuestionsReport implements Action {
  public readonly type = ModulesActions.AddStudentAssessmentQuestionsReport;
  constructor(public payload: StudentAssessmentQuestionsReportStore) {}
}

export class UpdateStudentAssessmentQuestionsChoiceOption implements Action {
  public readonly type =
    ModulesActions.UpdateStudentAssessmentQuestionsChoiceOption;
  constructor(public payload: StudentAssessmentQuestionsChoiceStore) {}
}
export class UpdateQuestionLastVisitedStatus implements Action {
  public readonly type = ModulesActions.UpdateQuestionLastVisitedStatus;
  constructor(public payload: StudentAssessmentLastVisitedQuestionStore) {}
}

export class ClearQuestionLastVisitedStatus implements Action {
  public readonly type = ModulesActions.ClearQuestionLastVisitedStatus;
  constructor(public payload: StudentAssessmentLastVisitedQuestionStore) {}
}

export type ModulesActionsUnion =
  | LoadModules
  | UpdatePreviewContent
  | AddPreviewFilters
  | UpdatePreviewFilters
  | AddModuleClassReport
  | AddActivityClassReport
  | AddStudentsProgress
  | ClearStudentsProgress
  | UpdateLastVisitedStatus
  | AddStudentClassReport
  | AddActivityStudentsProgress
  | ClearActivityStudentsProgress
  | AddStudentAssessmentQuestionsReport
  | UpdateStudentAssessmentQuestionsChoiceOption
  | UpdateQuestionLastVisitedStatus
  | ClearQuestionLastVisitedStatus;
