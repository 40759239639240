import {
  ActivityStoreClassReport,
  ModuleStoreClassReport,
} from '../../interfaces/class-report.interface';
import {
  AddPreviewFilter,
  Module,
  PreviewContentUpdate,
  UpdatePreviewFilter,
} from '../../interfaces/module.interface';
import {
  StudentReportStore,
  StudentClassReportStore,
  Student,
  StudentAssessmentQuestionsReportStore,
  StudentAssessmentLastVisitedQuestionStore,
  StudentAssessmentQuestionsChoiceStore,
} from 'up';
import { ModulesActions, ModulesActionsUnion } from '../actions/module.action';
import { ModuleState, initialModuleState } from '../state/module.state';

export const moduleReducer = (
  state = initialModuleState,
  action: ModulesActionsUnion
): ModuleState => {
  switch (action.type) {
    case ModulesActions.LoadModules: {
      return ModuleReducers.addModules(state, action.payload);
    }

    case ModulesActions.UpdatePreviewContent: {
      return ModuleReducers.updatePreviewContent(state, action.payload);
    }

    case ModulesActions.AddPreviewFilters: {
      return ModuleReducers.addPreviewFilters(state, action.payload);
    }

    case ModulesActions.AddModuleClassReport: {
      return ModuleReducers.addModuleClassReport(state, action.payload);
    }

    case ModulesActions.AddActivityClassReport: {
      return ModuleReducers.addActivityClassReport(state, action.payload);
    }

    case ModulesActions.UpdatePreviewFilters: {
      return ModuleReducers.updatePreviewFilter(state, action.payload);
    }
    case ModulesActions.AddStudentsProgress: {
      return ModuleReducers.addStudentsProgress(state, action.payload);
    }

    case ModulesActions.AddActivityStudentsProgress: {
      return ModuleReducers.addActivityStudentsProgress(state, action.payload);
    }

    case ModulesActions.ClearStudentsProgress: {
      return ModuleReducers.clearStudentsProgress(state, action.payload);
    }

    case ModulesActions.ClearActivityStudentsProgress: {
      return ModuleReducers.clearActivityStudentsProgress(
        state,
        action.payload
      );
    }

    case ModulesActions.UpdateLastVisitedStatus: {
      return ModuleReducers.UpdateLastVisitedStatus(state, action.payload);
    }

    case ModulesActions.AddStudentClassReport: {
      return ModuleReducers.addStudentClassReport(state, action.payload);
    }

    case ModulesActions.AddStudentAssessmentQuestionsReport: {
      return ModuleReducers.addStudentAssessmentQuestionsReport(
        state,
        action.payload
      );
    }

    case ModulesActions.UpdateStudentAssessmentQuestionsChoiceOption: {
      return ModuleReducers.updateStudentAssessmentQuestionsChoiceOption(
        state,
        action.payload
      );
    }

    case ModulesActions.UpdateQuestionLastVisitedStatus: {
      return ModuleReducers.updateQuestionLastVisitedStatus(
        state,
        action.payload
      );
    }

    case ModulesActions.ClearQuestionLastVisitedStatus: {
      return ModuleReducers.clearQuestionLastVisitedStatus(
        state,
        action.payload
      );
    }

    default:
      return state;
  }
};

export class ModuleReducers {
  static addModules(state: ModuleState, payload: Module[]): ModuleState {
    return {
      ...state,
      modules: payload,
    };
  }
  static addModuleClassReport(
    state: ModuleState,
    payload: ModuleStoreClassReport
  ): ModuleState {
    const updatedModule = state.modules.map((module, moduleIndex) => {
      return moduleIndex === payload.moduleIndex
        ? {
            ...module,
            classProgress: payload.classProgress,
          }
        : module;
    });

    return {
      ...state,
      modules: updatedModule,
    };
  }

  static addStudentsProgress(
    state: ModuleState,
    payload: StudentReportStore
  ): ModuleState {
    const updatedModule = state.modules.map((module, moduleIndex) => {
      if (moduleIndex === payload.moduleIndex && module.studentsProgress) {
        let _module = JSON.parse(JSON.stringify(module));
        _module.studentsProgress.studentList?.push(payload.studentList[0]);

        return {
          ...module,
          studentsProgress: _module.studentsProgress,
        };
      } else {
        return moduleIndex === payload.moduleIndex
          ? {
              ...module,
              studentsProgress: payload,
            }
          : module;
      }
    });

    return {
      ...state,
      modules: updatedModule,
    };
  }

  static addActivityStudentsProgress(
    state: ModuleState,
    payload: StudentReportStore
  ): ModuleState {
    const updatedModules = state.modules.map((module, moduleIndex) => {
      if (moduleIndex !== payload.moduleIndex) return module;

      const activityStudentProgress = module.activities.map((activity) => {
        if (
          activity.type === payload.activityType &&
          activity.studentsProgress
        ) {
          let _activity = JSON.parse(JSON.stringify(activity));
          _activity.studentsProgress.studentList?.push(payload.studentList[0]);

          return {
            ...activity,
            studentsProgress: _activity.studentsProgress,
          };
        } else {
          return activity.type === payload.activityType
            ? {
                ...activity,
                studentsProgress: payload,
              }
            : activity;
        }
      });

      return { ...module, activities: activityStudentProgress };
    });

    return {
      ...state,
      modules: updatedModules,
    };
  }

  static clearActivityStudentsProgress(
    state: ModuleState,
    payload: {
      moduleIndex: number;
      activityIndex: number;
      activityType: string;
    }
  ): ModuleState {
    const updateActivityStudentProgress = state.modules.map(
      (module, moduleIndex) => {
        if (moduleIndex !== payload.moduleIndex) return module;

        const updatedActivities = module.activities.map((activity) =>
          activity.type === payload.activityType
            ? { ...activity, studentsProgress: null }
            : activity
        );

        return { ...module, activities: updatedActivities };
      }
    );

    return {
      ...state,
      modules: updateActivityStudentProgress,
    };
  }

  static clearStudentsProgress(
    state: ModuleState,
    payload: number
  ): ModuleState {
    const updatedModule = state.modules.map((module, moduleIndex) => {
      let _module = JSON.parse(JSON.stringify(module));
      if (moduleIndex === payload && module.studentsProgress) {
        delete _module.studentsProgress;
      }
      return {
        ...module,
        studentsProgress: _module.studentsProgress,
      };
    });

    return {
      ...state,
      modules: updatedModule,
    };
  }

  static addActivityClassReport(
    state: ModuleState,
    payload: ActivityStoreClassReport
  ) {
    const updatedModules = state.modules.map((module, moduleIndex) => {
      if (moduleIndex === payload.moduleIndex) {
        const addActivityReport = module.activities.map((activity) => {
          if (activity.type === payload.activityType) {
            return {
              ...activity,
              classProgress: payload.activityReport,
            };
          }
          return activity;
        });

        return { ...module, activities: addActivityReport };
      }
      return module;
    });

    return {
      ...state,
      modules: updatedModules,
    };
  }

  static addPreviewFilters(
    state: ModuleState,
    payload: AddPreviewFilter
  ): ModuleState {
    const updatedModules = state.modules.map((module, moduleIndex) => {
      if (moduleIndex === payload.moduleIndex) {
        return {
          ...module,
          previewFilters: payload.previewFilters,
        };
      }
      return module;
    });

    return {
      ...state,
      modules: updatedModules,
    };
  }

  static updatePreviewFilter(
    state: ModuleState,
    payload: UpdatePreviewFilter
  ): ModuleState {
    const updatedModule = state.modules.map((module, moduleIndex) => {
      return moduleIndex === payload.moduleIndex
        ? { ...module, previewFilters: payload.previewFilter }
        : module;
    });

    return {
      ...state,
      modules: updatedModule,
    };
  }

  static updatePreviewContent(
    state: ModuleState,
    payload: PreviewContentUpdate
  ): ModuleState {
    const updatedModules = state.modules.map((module, moduleIndex) => {
      if (moduleIndex === payload.moduleIndex) {
        const updatedPreviewContent = module.activities.map(
          (activity, activityIndex) => {
            if (activityIndex === payload.activityIndex) {
              return {
                ...activity,
                previewContent: payload.previewContent,
              };
            }
            return activity;
          }
        );

        return { ...module, activities: updatedPreviewContent };
      }
      return module;
    });

    return {
      ...state,
      modules: updatedModules,
    };
  }

  static UpdateLastVisitedStatus(
    state: ModuleState,
    payload: number
  ): ModuleState {
    const updatedModule = state.modules.map((module, moduleIndex) => {
      return moduleIndex === payload
        ? { ...module, isLastVisited: true }
        : { ...module, isLastVisited: false };
    });

    return {
      ...state,
      modules: updatedModule,
    };
  }

  static addStudentClassReport(
    state: ModuleState,
    payload: StudentClassReportStore
  ): ModuleState {
    const updatedModule = state.modules.map((module, moduleIndex) => {
      if (moduleIndex === payload.moduleIndex && module.studentsProgress) {
        let _module = JSON.parse(JSON.stringify(module));
        _module.studentsProgress?.studentList[payload.pageIndex]?.students?.map(
          (student: Student) => {
            if (student.userxid === payload.userXid) {
              student.classReport = payload.classReport;
            }
          }
        );
        return {
          ...module,
          studentsProgress: _module.studentsProgress,
        };
      } else {
        return module;
      }
    });

    return {
      ...state,
      modules: updatedModule,
    };
  }
  static addStudentAssessmentQuestionsReport(
    state: ModuleState,
    payload: StudentAssessmentQuestionsReportStore
  ): ModuleState {
    const { moduleIndex, userxid, activityType, questions, eaid } = payload;

    const updatedModules = state.modules.map((module, index) => {
      if (index !== moduleIndex) return module;

      const { studentsAssessmentQuestionsReport = [] } = module;

      const updatedReport = studentsAssessmentQuestionsReport.map(
        (student: any) => {
          if (student[userxid]) {
            return {
              ...student,
              [userxid]: {
                ...student[userxid],
                eaid: eaid,
                [activityType]: questions,
              },
            };
          }

          return {
            ...student,
            [userxid]: {
              eaid: eaid,
              [activityType]: questions,
            },
          };
        }
      );

      return {
        ...module,
        studentsAssessmentQuestionsReport:
          updatedReport.length > 0
            ? updatedReport
            : [
                {
                  [userxid]: {
                    eaid: eaid,
                    [activityType]: questions,
                  },
                },
              ],
      };
    });

    return {
      ...state,
      modules: updatedModules,
    };
  }
  static updateStudentAssessmentQuestionsChoiceOption(
    state: ModuleState,
    payload: StudentAssessmentQuestionsChoiceStore
  ): ModuleState {
    const { moduleIndex, userxid, activityType, questionId, questionContent } =
      payload;

    const updatedModules = state.modules.map((module, index) => {
      if (index !== moduleIndex) return module;

      const updatedReports = module.studentsAssessmentQuestionsReport.map(
        (student: any) => {
          if (!(userxid in student)) return student;

          const studentActivity = student[userxid][activityType];
          if (!studentActivity) return student;

          const updatedQuestions = studentActivity.map((question: any) =>
            question.qid === questionId
              ? { ...question, questionContent }
              : question
          );

          return {
            ...student,
            [userxid]: {
              ...student[userxid],
              [activityType]: updatedQuestions,
            },
          };
        }
      );

      return {
        ...module,
        studentsAssessmentQuestionsReport: updatedReports,
      };
    });

    return {
      ...state,
      modules: updatedModules,
    };
  }

  static updateQuestionLastVisitedStatus(
    state: ModuleState,
    payload: StudentAssessmentLastVisitedQuestionStore
  ): ModuleState {
    const { moduleIndex, userxid, activityType, questionId, isLastVisited } =
      payload;

    const updatedModules = state.modules.map((module, index) => {
      if (index !== moduleIndex) return module;

      const updatedReports = module.studentsAssessmentQuestionsReport.map(
        (student: any) => {
          if (!(userxid in student)) return student;

          const studentActivity = student[userxid][activityType];
          if (!studentActivity) return student;

          const updatedQuestions = studentActivity.map((question: any) =>
            question.qid === questionId
              ? { ...question, isLastVisited: isLastVisited }
              : question
          );

          return {
            ...student,
            [userxid]: {
              ...student[userxid],
              [activityType]: updatedQuestions,
            },
          };
        }
      );

      return {
        ...module,
        studentsAssessmentQuestionsReport: updatedReports,
      };
    });

    return {
      ...state,
      modules: updatedModules,
    };
  }

  static clearQuestionLastVisitedStatus(
    state: ModuleState,
    payload: StudentAssessmentLastVisitedQuestionStore
  ): ModuleState {
    const { moduleIndex, userxid, activityType } = payload;

    const updatedModules = state.modules.map((module, index) => {
      if (index !== moduleIndex) return module;

      const updatedReports = module.studentsAssessmentQuestionsReport.map(
        (student: any) => {
          if (!(userxid in student)) return student;

          const studentActivity = student[userxid][activityType];
          if (!studentActivity) return student;

          const updatedQuestions = studentActivity.map((question: any) =>
            question.isLastVisited === true
              ? { ...question, isLastVisited: false }
              : question
          );

          return {
            ...student,
            [userxid]: {
              ...student[userxid],
              [activityType]: updatedQuestions,
            },
          };
        }
      );

      return {
        ...module,
        studentsAssessmentQuestionsReport: updatedReports,
      };
    });

    return {
      ...state,
      modules: updatedModules,
    };
  }
}
