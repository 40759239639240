import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { LtiParamsState } from '../../store/state/ltiparams.state';
import { getLtiParams } from '../../store/selectors/ltiparams.selector';
import { Observable, Subject, take, takeUntil } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'projects/vl/src/environments/environment';
import { getQuestionsAssessment } from '../../store/selectors/question.selector';
import {
  ActivityCompletionResponse,
  QuestionPlayerService,
  UPBsModalEvent,
  ToastsService,
  ModalSubmissionType,
  UPBsModalConfig,
  UPQuestionAssessmentResponse,
  UPUtilityService,
  ltiParams,
} from 'up';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AssignmentDetails } from '../../interfaces/assignment.interface';
import { getAssignmentActivityDetails } from '../../store/selectors/assignment.selector';
import { RemoveQuestionAssessmentType } from '../../store/actions/question.action';
@Component({
  selector: 'vl-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  constructor(
    private store: Store<LtiParamsState>,
    private router: Router,
    private upUtilityService: UPUtilityService,
    private modalService: NgbModal,
    private upQuestionPlayerService: QuestionPlayerService,
    private toastsService: ToastsService,
    private translate: TranslateService
  ) {}
  ltiParams$: Observable<ltiParams> = this.store.select(getLtiParams);
  ltiParams!: ltiParams;
  logo: string = '../../../assets/virtual-labs-logo-red-blue.svg';
  showExitButton: boolean = false;
  showSubmitButton: boolean = false;
  showActivitiesButton: boolean = false;
  role!: string;
  ltiAction!: string;
  private destroy$ = new Subject<boolean>();
  questionsAssessment$: Observable<UPQuestionAssessmentResponse[]> =
    this.store.select(getQuestionsAssessment);
  assignmentDetails$: Observable<AssignmentDetails> = this.store.select(
    getAssignmentActivityDetails
  );
  @ViewChild('submitCompleteAssignmentModal')
  submitCompleteAssignmentModal!: ElementRef;
  @ViewChild('submitInCompleteAssignmentModal')
  submitInCompleteAssignmentModal!: ElementRef;
  bsModalConfig: UPBsModalConfig = {
    title: '',
    submitType: ModalSubmissionType.CLOSE_MODAL,
    submitBtnText: '',
    closeBtnText: '',
    hideCloseBtn: false,
    hideFooter: false,
    featureType: '',
  };
  mdModalParams: NgbModalOptions = {
    size: 'md',
    keyboard: false,
    backdrop: 'static',
    centered: true,
  };
  incompleteQuestionCount!: number;
  eaid!: string;
  activityType!: string;
  ngOnInit() {
    this.getLtiParams();
    this.routerPathCheck();
  }

  _setActivitiesButton(url: string) {
    const activityUrlOptions = [
      '/assignment/activity/simulation',
      '/assignment/activity/preLab',
      '/assignment/activity/postLab',
    ];

    this.showActivitiesButton =
      activityUrlOptions.indexOf(url) !== -1 ? true : false;
  }

  routerPathCheck() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (
          this.role === 'Learner' ||
          (this.role === 'Instructor' && this.ltiAction === 'launch')
        ) {
          this._setActivitiesButton(event.url);
          this._setExitButton(event.url);
          this._setShowSubmitButtom(event.url);
        }
        this.activityType = event.url.includes('preLab')
          ? 'preLab'
          : event.url.includes('postLab')
          ? 'postLab'
          : 'simulation';
      }
    });
  }

  _setExitButton(url: string) {
    this.showExitButton =
      url === '/assignment' || url === '/assignment/activities' ? true : false;
  }

  _setShowSubmitButtom(url: string) {
    this.assignmentDetails$
      .pipe(takeUntil(this.destroy$))
      .subscribe((assignmentActivityDetails: AssignmentDetails) => {
        this.showSubmitButton =
          !assignmentActivityDetails.isSubmitted &&
          this._isActivityInProgress(assignmentActivityDetails) &&
          url.includes('/assignment/activity');
      });
  }

  _isActivityInProgress(assignmentDeatils: AssignmentDetails) {
    return (
      assignmentDeatils.activities.filter(
        (activity) =>
          (activity.type === 'preLab' || activity.type === 'postLab') &&
          activity.status === 'inProgress'
      ).length > 0
    );
  }

  onExit() {
    this.upUtilityService.closeApp(`${environment.apiUrl}/vl`);
  }

  getLtiParams() {
    this.ltiParams$
      .pipe(takeUntil(this.destroy$))
      .subscribe((ltiParams: ltiParams) => {
        this.ltiParams = ltiParams;
        this.role = this.ltiParams.role;
        this.ltiAction = this.ltiParams.ltiaAction;
      });
  }

  onClickActivities() {
    this.router.navigateByUrl('/assignment/activities');
  }

  getActivityCompletion(eaid: string) {
    this.upQuestionPlayerService
      .activityCompletion(
        `${environment.apiUrl}/vl/assignment/activity/assessment/completion`,
        eaid
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (ActivityCompletionResponse: ActivityCompletionResponse) => {
          const completeQuestionStatus = Object.values(
            ActivityCompletionResponse.compstatus
          );

          this.incompleteQuestionCount =
            completeQuestionStatus.length -
            completeQuestionStatus.filter((item) => item.answered === 'true')
              .length;

          this.bsModalConfig.title = 'LEARNER.ASSIGNMENT_SUBMIT_MODAL.TITLE';
          this.bsModalConfig.closeBtnText =
            'LEARNER.ASSIGNMENT_SUBMIT_MODAL.CANCEL_BUTTON_TEXT';
          this.bsModalConfig.submitBtnText =
            'LEARNER.ASSIGNMENT_SUBMIT_MODAL.SUBMIT_BUTTON_TEXT';
          this.bsModalConfig.submitType =
            ModalSubmissionType.ASSIGNMENT_SUBMIT_BUTTON;
          this.bsModalConfig.featureType = 'assignment-submit';
          const assignmentModal =
            this.incompleteQuestionCount > 0
              ? this.submitInCompleteAssignmentModal
              : this.submitCompleteAssignmentModal;

          this.modalService.open(assignmentModal, this.mdModalParams);
        },
        error: () => {
          this.translate
            .get('LEARNER.ASSIGNMENT_SUBMIT_MODAL.SUBMIT_FAILED')
            .subscribe({
              next: (submitFailed) => {
                this.toastsService.error({
                  message: submitFailed,
                });
              },
            });
        },
      });
  }

  onClickSubmit() {
    this.questionsAssessment$
      .pipe(takeUntil(this.destroy$), take(1))
      .subscribe(
        (questionAssessmentDetails: UPQuestionAssessmentResponse[]) => {
          const questionAssessment = questionAssessmentDetails.find(
            (questionAssessment: UPQuestionAssessmentResponse) =>
              questionAssessment.assessmentInfo.activityType ===
              this.activityType
          );
          this.eaid = questionAssessment?.assessmentInfo.eaid!;
          this.getActivityCompletion(this.eaid);
        }
      );
  }

  onConfirmSubmit() {
    this.upQuestionPlayerService
      .activitySubmit(
        `${environment.apiUrl}/vl/assignment/activity/assessment/submit/${this.eaid}`,
        {
          type: this.activityType,
        }
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.store.dispatch(
            new RemoveQuestionAssessmentType(this.activityType)
          );
          this.router.navigateByUrl('/assignment/activities');
        },
        error: () => {
          this.translate
            .get('LEARNER.ASSIGNMENT_SUBMIT_MODAL.SUBMIT_FAILED')
            .pipe(takeUntil(this.destroy$))
            .subscribe({
              next: (failedMessage) => {
                this.toastsService.error({
                  message: failedMessage,
                });
              },
            });
        },
      });
  }

  handleModalEvent(event: UPBsModalEvent) {
    switch (event.type) {
      case ModalSubmissionType.ASSIGNMENT_SUBMIT_BUTTON:
        this.onConfirmSubmit();
        break;
    }
    this.modalService.dismissAll();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
